import RESUME from './pete/resume'

export default {
  name: "pete",
  image: "pete-front-bw-bright",
  /*image: "pete-front2",*/
  /*image: "pete-looking6",*/
  url: "https://petedelaurentis.com",
  firstName: "Pete",
  lastName: "DeLaurentis",
  title: "Product Designer",
  resumeTitle: "DESIGN + ENGINEERING",
  categories: ["app", "patent", "hardware", "web", "cloud"],
  projects: [{
                "era": "Twelve",
                "dates": "Nov 2021 - Present",
                "cards": [
                  "kiwi",
                  "leaf-factory",
                  "leaf-lab",
                  "bonsai",
                  "gecko",
                  "opus-electrolyzer",
                  "airplant",
                  /*
                  "twelve-hiring-exercises",*/
                  /*"twelve-team-org",
                  "twelve-team-rituals",
                  "career-conversations-framework",*/
                  "twelve-hasura",
                  "twelve-kubernetes",
                  "timescale-db",
                  "twelve-design-system",
                  /*"team-cards",*/
                ]
             },
             {
              era: "PicnicHealth",
              dates: "Oct 2019 - Oct 2021",
              cards: [
                "patient-timeline",
                "ai-model-api",
                "hipe",
                "hasura-picnic",
                "patient-surveys",
                "flex-custom-studies",
                "kubernetes-feature-branches-picnic",
                "mindb",
                "design-system-expansion",
                "postgres-infra-metabase-audit",
                /*"hipe-patient-infrastructure-team",
                "hiring-exercises-picnic"*/
              ]
            },
            { era: "UpGift", dates: "Jun 2017 - Oct 2019", cards: ["upgift-app", "upgift-website", "upgift-video",  "upgift-payments", "upgift-social-network", "upgift-search"]},
            { era: "WayTools", dates: "Jan 2011 - Jun 2017", cards: ["textblade-keyboard", "textblade-app", "textblade-website", "textblade-order-system",  "remote-scopes",  "realtime-device-sync", "textblade-video", "hands-on-video", "universal-keyboard-video", "multimap-video", "android-video", "textblade-international-layouts", "fingerbot", "forcebot", "textblade-patent", "textblade-design-patent", "textblade-cursor-patent", "textblade-modkeys-patent"]},
            { era: "NextEngine", dates: "Jan 2002 - 2011", cards: ["scanstudio", "nextengine-scanner", "rapidworks", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "nextwiki", "nextengine-licensing", "handheld-scanner-patent", "photo-chat-patent", "integrated-capture-patent", "shape-search-patent"]},
            { era: "Personal", dates: "2008 - Present", 
              cards: [
                "planner",
                "portfolio", 
                "retro-rabbit-run", 
                "retro-rabbit-multiply", 
                "storyjar", 
              ]
            },
           ],
  menu: {
    index: {
      top: {
        name: "top",
        title: "PETE"
      },
      portfolio: {
        name: "portfolio",
        title: "PORTFOLIO",
        darken: true,
      },
      cv: {
        name: "cv",
        title: "CV",
        darken: true,
      },
      impact: {
        name: "impact",
        title: "IMPACT",
        darken: true,
      },
      skills: {
        name: "skills",
        title: "SKILLS",
        darken: true,
      },
      about: {
        name: "about",
        title: "ABOUT",
        darken: true,
      },
      talk: {
        name: "talk",
        title: "TALK",
        darken: true,
      },
    },
    sequence: ["top", "portfolio", "skills", "impact", "about", "cv"]
  },
  footer: {
    columnWidth: 250,
    index: {
      copyright: {
          symbol: "copyright",
          text: "2024 Peter DeLaurentis",
      },
      location: {
          symbol: "location",
          text: "San Francisco, CA",
      },
      dribbble: {
          symbol: "linkedin",
          url: "https://www.linkedin.com/in/pete-delaurentis-3622192/",
          text: "Connect on LinkedIn",
      },
      email: {
          symbol: "email",
          url: "mailto:pete.delaurentis@gmail.com",
          text: "pete.delaurentis@gmail.com",
      },

    },
    sequence: ["copyright", "location", "dribbble", "email"]
  },
  shortDescription: "Full spectrum engineering & design.",
  /*description: "Full spectrum design.  I empower users\nwith simple tools that do amazing things.",
  description: "Full spectrum design.  I bring new capabilities to the mainstream with the art of radical simplicity.",
  description: "Full spectrum design.  Simplicity is the key\nthat unlocks new capabilities for the mainstream.",
  description: "Full spectrum design.  Simplicity unlocks new capabilities for the mainstream.",
  description: "Full spectrum design.  Capability and simplicity\nare not mutually exclusive.",
  description: "Full spectrum design.  I empower users with\nnew capabilities through the art of simplicity.",
  description: "Full spectrum design.  I bring new tech\nto the mainstream with the art of simplicity.",
  description: "Full spectrum design.  Simple on the outside.  Breakthrough technology on the inside.",
  description: "Full spectrum design.  I bring breakthrough\ntech to the mainstream via radical simplicity.",
  description: "Full spectrum design.  I empower users with\nnew technology through the art of simplicity.",*/
  /*description: "Full spectrum design.  I build simple\ntools with breakthrough capabilities.",*/
  /*description: "I lead the design of effortless products that\nempowers users with breakthru capabilities.",*/
  /*description: "I lead the design of breakthrough products\nthat empower users through simplicity.",*/
  /*description: "I lead the creation of experiences that alloy\nsimple design with breakthrough capabilities.",*/
  //description: "I lead the creation of products that unite\nsimplicity with breakthrough capability.",
/*  description: "I lead the creation of breakthrough products\nthat maximize both simplicity and capability.",
  description: "I design breakthrough products that\ndeliver sophsiticated capabilities in a simple package.",*/
  /*description: "I make simple and novel user experiences\nthat deliver sophisticated capabilities.\n",*/

  /*description: "I create simple and novel user experiences\nthat deliver surprisingly powerful capabilities.",*/
  /*description: "Developer, designer, and leader.  Creator of\nbrave new products and $100M+ in revenue.",*/
  /*description: "Engineering and design leader.  Creator of\nbrave new products and $100M+ in revenue.",*/
  description: "Engineering and design leader.  Creator of\nbrave new products and high performing teams.",

  /*  description: "I design products that empower users\nwith simplicity, empathy, and capability.",
*/
  //description: "I design breakthrough products\nthat empower users through simplicity.",
  //description: "I lead the design of products that unite\nsimplicity with breakthrough capability.",
  /*description: "I lead the creation of products that unlock\nbreakthrough capability via the art of simplicity.",*/
/*
  description: "Full spectrum design.  Simplicity empowers\nus with new capabilities.",
  /*Natural, fluid designs that make everyone a power user.*/
  /*Simple, elegant\nexperiences that empower users with new capabilities.*/
  impact: {
    illustration: {
      image: "planting-sapling.png",
      side: "right",
      width: 450,
      height: 450,
    },
    paragraphs: [
      /*"I love creating brave new products that enable cultural shifts and help us move into deeper harmony with each other and our planet.",*/
/*      "I currently oversee the Software division at Twelve, leading a talented team of 18. My role involves direct management of both EMs and staff-level ICs, as well as design and product functions.  Our industrial photosynthesis technology promises a fossil-free future, and is a key ingredient in the fight against climate change.",*/
      "I love paving new territory, and creating products that solve meaningful problems.  When technology is paired with insight and empathy for the needs of others, great things can happen.",
      "I'm grateful to have had multiple opportunities in my career to make a meaningful contribution: accelerating medical research into rare diseases (PicnicHealth), creating more experiences and less waste (UpGift), helping users express themselves fully wherever they are (TextBlade), democratizing the creation of 3D content and allowing for more efficient manufacturing (NextEngine).",
      "I currently oversee Software at Twelve, leading a talented team of 18. Our industrial photosynthesis technology helps fight climate change by converting CO2 into sustainable jet fuel and consumer products.",

      //My role involves direct management of both EMs and staff-level ICs, as well as design and product functions.  
    ],
  },
  about: {
    illustration: {
      image: "three-people.png",
      side: "left",
      width: 450,
      height: 450,
    },
    paragraphs: [
      /*"I love creating brave new products that enable cultural shifts and help us move into deeper harmony with each other and our planet.",*/
      /*"I build high performing teams that are powered by a culture of excellence and empathy.  I help my teams level up their skills, deliver on time, and lay a firm foundation for the future with clean architecture and a codebase that's easy to maintain and extend.  I also enjoy mentoring managers and future leaders.",*/
      "I build high performing teams that are powered by a culture of excellence and empathy.  I help my teams level up their skills, communicate effectively, deliver on time, and lay a firm foundation for the future with clean architecture and a codebase that's easy to read and extend.",
      /*"I love paving new territory, and helping lead the creation of products that solve meaningful problems.  When technology is paired with insight and empathy for the needs of others, really great things can happen.",
      "I'm grateful to have had multiple opportunities in my career to make a meaningful contribution: helping accelerate medical research into rare diseases (PicnicHealth), reducing the load on our planet and helping people experience more life and less clutter (UpGift), helping people to express themselves fully wherever they are (TextBlade), democratizing the creation of 3D content and allowing for more efficient manufacturing (NextEngine).",*/
      "I believe that engineering and design are practices that work best when all ideas are welcome, and all viewpoints are honored.  Insight can come from anywhere, and every single person, situation, and interaction can be a powerful teacher – if we listen.",
      "When I'm not working, I love playing, cooking, writing stories, and making art with my 3 amazing kids.  I'm a lifelong student of meditation, and believe that inner work we do and gratitude we cultivate forms the foundation for our outer lives.",
    ],
    cards: {
      index: {
        "unicorn": {
          type: "about",
          name: "unicorn",
          image: "about/electric-unicorn",
          image: "about/unicorn-white-background",
          /*thumb: { zoom: 3.5, resolution: 500, x: 1, y: 0 },*/
          theme: "light",
          aspectRatio: 0.55,
          /*aspectRatio: 0.40,*/
          title: "Design + Engineering",
          subtitle: "Hardware",
          categories: ["hardware"],
          description: "Full QWERTY keyboard magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger.   Keys are multi-touch surfaces with luxurious key travel.",
          /*Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.*/
          content: [
            { text: "TextBlade is a full QWERTY keyboard that magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger. Each key features a MultiTouch surface which detects the position of your finger, and a magnetic suspension for luxurious key feel.", },
          ],
        }
      },
      sequence: ["unicorn"],
    }
  },
  cv: {
    url: "https://petedelaurentis.com/cv/cv-pete-delaurentis-sep-4-2024.pdf",
    button: "Download PDF",
    paragraphs: [
      "Looking for my resume?"
    ],
  },
  talk: {
    email: "pete.delaurentis@gmail.com",
    button: "Yes, Let's Connect",
    paragraphs: [
      "Want to learn more about my work?"
    ],
  },
  jobs: RESUME.jobs,
  skills: RESUME.skills,
};
